import React from 'react'

// Styled Elements
import {
  SubHeaderWrapper,
  SubHeaderNavList,
  SubHeaderNavListItem,
  SubHeaderNavListLink,
} from './ReportsSubHeader.elements'

function ReportsSubHeader() {
  return (
    <SubHeaderWrapper>
      <SubHeaderNavList>
        <SubHeaderNavListItem>
          <SubHeaderNavListLink exact activeClassName="active" to="/reports">
            Addresses
          </SubHeaderNavListLink>
        </SubHeaderNavListItem>
        <SubHeaderNavListItem>
          <SubHeaderNavListLink exact activeClassName="active" to="/reports/transaction">
            Transactions
          </SubHeaderNavListLink>
        </SubHeaderNavListItem>
        <SubHeaderNavListItem>
          <SubHeaderNavListLink exact activeClassName="active" to="/reports/travelrule">
            Travel Rule
          </SubHeaderNavListLink>
        </SubHeaderNavListItem>
      </SubHeaderNavList>
    </SubHeaderWrapper>
  )
}

export default ReportsSubHeader
