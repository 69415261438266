/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Store
import { actions } from 'core/store'

// Constants
// import { BLOCKCHAIN_SYMBOL_MONO_SVGS } from 'common/constants/blockchainMonoSvgs'
// import {
//   ADDRESS_REGEX_OPTIONS,
//   ADDRESS_CHAIN_OPTIONS_MAPPING,
//   ADDRESS_CHAIN_OPTIONS,
//   INTEGRATION_PARTNERS_ID,
// } from 'common/constants/formOptions'

// Hooks
import {
  useCreateOrUpdateAddress,
  // useGetAddressChainRecommendation
} from 'core/hooks/api'
import { useLocalStorage } from 'core/hooks/storage'

// Styled Elements
import {
  Wrapper,
  SearchBarWrapper,
  SearchInputWrapper,
  SearchBarContainer,
  SearchInputOptions,
  Backdrop,
  CloseIconWrapper,
  Title,
  ChainChoiceWrapper,
  ChainChoice,
} from './AddressSearchBar.elements'

// Views
import { TextField, SimpleSelect, Button } from 'views/components'

// Assets
import { ScanIcon, CloseIcon } from 'assets/images'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function AddressSearchBar(props) {
  // props
  const { actions, address } = props

  // destructure
  const { showAlert, setScannedAddress, toggleInfoModal, setInfoModalDetails } = actions
  const { addressChains, defaultAddressProvider } = address

  // local states
  const [isActive, setIsActive] = useState(false)
  const [addressInput, setAddressInput] = useState('')
  const [chain, setChain] = useState('')
  const [chainSymbol, setChainSymbol] = useState('')
  const [defaultProvider, setDefaultProvider] = useState()
  const [selectedProvider, setSelectedProvider] = useState()
  const [availableChains, setAvailableChains] = useState()
  // const [matchedChains, setMatchedChains] = useState([])
  // const [otherChains, setOtherChains] = useState([])

  // hooks
  const {
    createOrUpdateAddress,
    createOrUpdateAddressData,
    createOrUpdateAddressError,
    isCreateOrUpdateAddressLoading,
  } = useCreateOrUpdateAddress()
  // const {
  // getAddressChainRecommendation,
  // getAddressChainRecommendationData,
  // getAddressChainRecommendationError,
  // isGetAddressChainRecommendationLoading,
  // } = useGetAddressChainRecommendation()
  const [userCredentials] = useLocalStorage('userCredentials', {})

  // Functions
  function handleSubmit() {
    const firstChild = Object.keys(addressChains?.risk[selectedProvider])[0]
    const firstChain = addressChains?.risk[selectedProvider][firstChild]
    const integration_id = firstChain?.integration_id
    createOrUpdateAddress({
      address: addressInput,
      chain,
      name_first: userCredentials.name_first,
      name_last: userCredentials.name_last,
      integration_id,
    })
    setAddressInput('')
    setChain('')
  }
  // function handleChainRecommendation(data) {
  //   const currentMatchedChains = []
  //   if (data?.length > 0) {
  //     data.map((item) => {
  //       currentMatchedChains.push({ disabled: false, value: item, label: item })
  //     })
  //   }
  //   if (currentMatchedChains.length === 1) {
  //     if (currentMatchedChains[0]?.value === 'near') {
  //       return setMatchedChains([])
  //     }
  //   }
  //   if (currentMatchedChains.length < 1) return setMatchedChains([])
  //   if (currentMatchedChains.length > 0) return setMatchedChains(currentMatchedChains)
  // }

  function handleAddressChange(inputValue) {
    setAddressInput(inputValue)
    // getAddressChainRecommendation({ address: inputValue, chain })
    // handleChainMatch(inputValue)
  }
  function handleAvailableChains() {
    const providers = { ...addressChains?.financial }
    let allChains = {}
    Object.keys(providers).forEach((item) => {
      if (item === 'FDBDM') {
        allChains = { ...allChains, ...providers[item] }
      }
    })
    setAvailableChains(allChains)
  }
  // useEffect(() => {
  //   if (getAddressChainRecommendationData) handleChainRecommendation(getAddressChainRecommendationData)
  // }, [getAddressChainRecommendationData])
  useEffect(() => chain && handleSubmit(), [chain])
  useEffect(() => {
    handleAvailableChains()
  }, [addressChains])
  useEffect(() => {
    if (defaultAddressProvider) {
      setDefaultProvider(defaultAddressProvider?.risk)
      setSelectedProvider(defaultAddressProvider?.risk?.partner_name)
    }
  }, [defaultAddressProvider])
  useEffect(() => !isActive && setAddressInput(''), [isActive])
  useEffect(() => {
    if (createOrUpdateAddressData) {
      setScannedAddress(createOrUpdateAddressData)
      setIsActive(false)
    }
  }, [createOrUpdateAddressData])
  useEffect(() => {
    // TODO ADD PROPER ERROR MSG SENT FROM SERVER
    if (createOrUpdateAddressError) showAlert({ type: 'error', message: 'An error occured in screening address' })
  }, [createOrUpdateAddressError])
  useEffect(() => {
    if (isCreateOrUpdateAddressLoading) {
      toggleInfoModal(true)
      setInfoModalDetails({
        title: 'Scanning Address',
        content: 'This process may take over 60 seconds, depending on the volume of data.',
        loading: true,
      })
    } else {
      toggleInfoModal(false)
      setInfoModalDetails()
    }
  }, [isCreateOrUpdateAddressLoading])
  return (
    <Wrapper>
      <SearchBarWrapper isActive={isActive}>
        <SearchBarContainer isActive={isActive}>
          <SearchInputWrapper isActive={isActive}>
            {isActive && (
              <CloseIconWrapper onClick={() => setIsActive(false)}>
                <CloseIcon />
              </CloseIconWrapper>
            )}
            <ScanIcon />
            <TextField
              placeholder={isCreateOrUpdateAddressLoading ? 'loading...' : !isActive && 'Screen a wallet address'}
              onClick={() => {
                setIsActive(true)
              }}
              value={addressInput}
              onChange={(e) => handleAddressChange(e.currentTarget.value)}
            />
            {/* {isActive &&
              <SearchInputOptions>
                {chain && addressInput && (
                  <SimpleSelect
                    id="addressSearchOptionsProvider"
                    label="Provider"
                    placeholder={defaultProvider?.partner_name || 'Provider'}
                    options={
                      addressChains?.risk &&
                      Object.keys(addressChains?.risk)
                        ?.map((item) => {
                          if (addressChains?.risk[item][chainSymbol]) {
                            return { label: item, value: item }
                          }
                        })
                        .filter((item) => item !== undefined)
                    }
                    onChange={(value) => {
                      setSelectedProvider(value)
                    }}
                    value={selectedProvider}
                    disabled={!addressChains}
                  />
                )}
                <SimpleSelect
                  id="addressSearchOptionsChain"
                  placeholder="Chain"
                  options={
                    addressChains?.risk &&
                    Object.keys(addressChains?.risk?.[selectedProvider])?.map((item) => {
                      const chainItem = addressChains?.risk?.[selectedProvider][item]
                      return {
                        label: item,
                        value: chainItem?.ospree_chain_name,
                        SVG: BLOCKCHAIN_SYMBOL_MONO_SVGS[item],
                      }
                    })
                  }
                  onChange={(v) => setChain(v)}
                  disabled={!selectedProvider}
                />
                <Button disabled={!chain || !addressInput} onClick={() => handleSubmit()}>
                  Screen
                </Button>
              </SearchInputOptions>
              } */}
          </SearchInputWrapper>
          {addressInput && isActive && !isCreateOrUpdateAddressLoading && (
            <ChainChoiceWrapper isActive={isActive}>
              {addressChains?.length < 1 && <Title>No chain has been identified for the provided address hash.</Title>}
              {availableChains &&
                Object.keys(availableChains).map((item) => (
                  <ChainChoice
                    key={availableChains[item].symbol}
                    // style={{ opacity: item.disabled && 0.5, cursor: item.disabled && 'default' }}
                    onClick={() => {
                      setChain(availableChains[item].ospree_chain_name)
                      // setChainSymbol(availableChains[item].symbol)
                    }}
                    active={availableChains[item].ospree_chain_name === chain}
                  >
                    {availableChains[item].symbol} - {availableChains[item].ospree_chain_name}
                  </ChainChoice>
                ))}
            </ChainChoiceWrapper>
          )}
        </SearchBarContainer>
      </SearchBarWrapper>
      <Backdrop
        onClick={() => setIsActive(false)}
        style={{ zIndex: isActive ? '5' : '-1', opacity: isActive ? '0.5' : '0' }}
      />
    </Wrapper>
  )
}

// Default Props
AddressSearchBar.defaultProps = {
  actions: {},
}

// Proptypes Validation
AddressSearchBar.propTypes = {
  actions: PropTypes.shape({
    setShowHeaderLoader: PropTypes.func,
    showAlert: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    setScannedAddress: PropTypes.func,
    toggleInfoModal: PropTypes.func,
    setInfoModalDetails: PropTypes.func,
  }),
  address: PropTypes.shape({
    addressChains: PropTypes.shape({}),
    defaultAddressProvider: PropTypes.shape({}),
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(AddressSearchBar)
