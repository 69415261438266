import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Assets
import PlusWhiteIcon from 'assets/images/plus-white'

// Store
import { actions } from 'core/store'

// Hooks
import { useFetchAllTransfers } from 'core/hooks/api'

// Styled Elements
import { TravelRuleTableWrapper } from './TravelRuleTable.elements'

// Views
import { Button, AccordionTable, EmptyTablePlaceholder, LoadingTablePlaceholder } from 'views/components'

// Layouts
import { TravelRuleTableItem, TravelRuleTableFilter } from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function TravelRuleTable(props) {
  // Destructure
  const { actions, filters, ui } = props
  const { travelRuleFilters } = filters
  const { isPageTableUpdated } = ui
  const { setIsTransferDrawerOpen, setTravelRuleFilters, setIsPageTableUpdated, toggleTransferReportModal } = actions

  // Hooks
  const [transfersData, setTransfersData] = useState({ items: [], total: 0 })
  const { getAllTransfers, allTransferData, isAllTransferLoading } = useFetchAllTransfers()

  // State
  const [isTransferLoading, setIsTransferLoading] = useState(false)

  // Functions
  const fetchData = async () => {
    getAllTransfers(travelRuleFilters)
  }
  const handleOnTransferSearch = (data) => {
    if (!data) {
      return getAllTransfers(travelRuleFilters)
    }
    setTransfersData({ items: data, total: 0 })
  }

  // UseEffects
  useEffect(() => fetchData(), [travelRuleFilters])
  useEffect(() => allTransferData && setTransfersData(allTransferData), [allTransferData])
  useEffect(() => {
    if (isPageTableUpdated) {
      fetchData()
      setIsPageTableUpdated(false)
    }
  }, [isPageTableUpdated])

  return (
    <TravelRuleTableWrapper>
      <AccordionTable
        minWidth={700}
        totalItems={transfersData.total}
        tableFilters={travelRuleFilters}
        setTableFilters={setTravelRuleFilters}
        filterComponents={
          <TravelRuleTableFilter
            setIsTransferLoading={setIsTransferLoading}
            onTransferSearch={handleOnTransferSearch}
          />
        }
        headerOptions={
          <>
            <Button
              size="small"
              variant="outlined"
              startIcon={<PlusWhiteIcon />}
              onClick={() => {
                toggleTransferReportModal()
              }}
            >
              Report
            </Button>
            <Button
              size="small"
              variant="outlined"
              startIcon={<PlusWhiteIcon />}
              onClick={() => {
                setIsTransferDrawerOpen(true)
              }}
            >
              Transfer
            </Button>
          </>
        }
      >
        {transfersData.items.length > 0 &&
          !isAllTransferLoading &&
          !isTransferLoading &&
          transfersData.items.map((singleData) => (
            <TravelRuleTableItem key={singleData.transfer_id} data={singleData} />
          ))}
        {(isAllTransferLoading || isTransferLoading) && <LoadingTablePlaceholder />}
        {transfersData?.items?.length < 1 && !isAllTransferLoading && !isTransferLoading && (
          <EmptyTablePlaceholder setFilter={setTravelRuleFilters} />
        )}
      </AccordionTable>
    </TravelRuleTableWrapper>
  )
}

// Default Props
TravelRuleTable.defaultProps = {
  actions: {},
  ui: {},
  filters: {},
}

// Proptypes Validation
TravelRuleTable.propTypes = {
  actions: PropTypes.shape({
    setTravelRuleFilters: PropTypes.func,
    setIsTransferDrawerOpen: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    toggleTransferReportModal: PropTypes.func,
  }),
  ui: PropTypes.shape({
    isPageTableUpdated: PropTypes.bool,
  }),
  filters: PropTypes.shape({
    travelRuleFilters: PropTypes.instanceOf(Object),
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(TravelRuleTable)
